
  import Vue from 'vue';

  export default Vue.extend({
    name: 'es-price-plan-card-skeleton',
    props: {
      times: {
        default: 2,
        type: Number,
      },
    },
  });
