
  import Vue, { PropType } from 'vue';
  import { mapGetters } from 'vuex';
  import { SubscriptionBenefitsModal } from '@/components/shared/subscription-benefits-modal';

  export default Vue.extend({
    name: 'es-pricing-plan-card',

    components: {
      'es-subscription-benefits-modal': SubscriptionBenefitsModal,
    },

    props: {
      benefits: {
        default: null,
        type: Array as PropType<string[]>,
      },
      item: {
        required: true,
        type: Object,
      },
      to: {
        default: null,
        type: String,
      },
    },

    data: () => ({
      isModalOpen: false,
    }),

    computed: {
      ...mapGetters({
        isAuthenticated: 'session/isAuthenticated',
      }),
    },

    methods: {
      onSelect() {
        if (this.to) {
          this.$router.push(this.to);
        } else {
          this.$emit('on-select', this.item);
        }
      },
    },
  });
