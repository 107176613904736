
  import Vue from 'vue';
  import PricingPlanCard from './PricingPlanCard.vue';

  export default Vue.extend({
    name: 'es-pricing-plan',

    components: {
      'es-pricing-plan-card': PricingPlanCard,
    },

    props: {
      showTitle: {
        type: Boolean,
        default: true,
      },
      subscriptions: {
        type: Array,
        required: true,
      },
    },

    data: () => ({
      selectedType: 'monthly',
    }),
  });
